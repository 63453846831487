<template>
  <div class="v_cash_out_history g-flex-column">
    <head-back>
        <template v-slot:returnText>
        <span>返回</span>
      </template>

        <template v-slot:default>
        <span>提现记录</span>
      </template>
    </head-back>
    <div class="v-cash-out-history-container">
      <van-list class="v-cash-out-history-list"  v-show="cashHistoryList.length" v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
        <div class="v-cash-out-history-list-item g-flex-align-start" v-for="(item, index) in cashHistoryList" :key="index" @click="detailsItemClick(item)">
          <div class="v-cash-out-history-item-left">
            <i class="iconfont icon-yinhangqia" v-if="item.bank_type === 1"></i>
            <i class="iconfont icon-zhifubao" v-if="item.bank_type === 2"></i>

          </div>
          <div class="v-cash-out-history-item-right">
            <div class="v-cash-out-history-item-right-top g-flex-align-center">
              <p class="v-cash-out-history-item-right-top-money">-{{item.pay_money}}</p>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-fail" v-if="item.status == 0">失败</span>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-ing" v-if="item.status == 1">审核中</span>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-success" v-if="item.status == 2">已完成</span>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-deal" v-if="item.status == 3">银行处理中</span>
            </div>
            <div class="v-cash-out-history-item-right-bottom g-flex-align-center">
              <p class="v-cash-out-history-item-right-bottom-type" v-if="item.type === 1">本金提现</p>
              <p class="v-cash-out-history-item-right-bottom-type" v-if="item.type === 2">佣金提现</p>
              <span class="v-cash-out-history-item-right-top-status">{{ formatDate(item.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
            </div>
          </div>
        </div>
      </van-list>

       <!-- 没有列表 -->
      <NoList ref="NoList" v-show="!cashHistoryList.length"/>
    </div>

    <!-- 提现详情 -->
    <cash-out-history-pop ref="CashOutHistoryPop"/>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { PAGE, LIMIT } from '@/utils/constants.js'
  import { formatDate } from '../../utils/index.js'
  import CashOutHistoryPop from '@/views/cashouthistory/CashOutHistoryPop.vue'
  import { apiGetCashList } from '@/utils/api.js'
  export default {
    components: { HeadBack, CashOutHistoryPop },
    data() {
      return {
        num: 10,
        loading: false,
        finished: false,
        cashHistoryList: [],
        form: {
          status: 'ALL',
          page: PAGE,
          limit: LIMIT,
        },
      }
    },
    created() {
      this.apiGetCashListHandel()
    },
    methods: {
      // 获取提现记录
      async apiGetCashListHandel() {
        const { success, data } = await apiGetCashList(this.form)
        if(!success) return
        this.cashHistoryList = this.cashHistoryList.concat(data.list) 
        this.loading = false
        this.finished = data.list.length < this.form.limit
        console.log(data)
      },

      formatDate,
      detailsItemClick(item) {
        this.$refs.CashOutHistoryPop.onShow(true, item)
      },
      onLoad() {
        this.form.page++
        this.apiGetCashListHandel()
      }
    }
  }
</script>

<style lang="scss">
.v_cash_out_history {
  height: 100%;
  background: #F1F1F1;
  .v-cash-out-history-container {
    flex: 1;
    overflow: auto;
    padding-top: 10px;
    .v-cash-out-history-list {
      background: $white;
      .v-cash-out-history-list-item {
        padding:14px 20px 0px 22px;
        .v-cash-out-history-item-left {
          padding-right: 16px;
          .icon-zhifubao {
            color: #3889FF;
            font-size: 30px;
          }
          .icon-yinhangqia {
            font-size: 30px;
            color: #FB560A;
          }
        }
        .v-cash-out-history-item-right {
          border-bottom: 1px solid #DCDCDC;
          padding-bottom: 10px;
          flex: 1;
          .v-cash-out-history-item-right-top {
            .v-cash-out-history-item-right-top-money {
              flex: 1;
              font-size: 20px;
              color: $main_color;
            }
            .v-cash-out-history-item-right-top-status {
              font-size: 14px;
              &.v-cash-out-history-item-right-top-status-ing {
                color: #3889FF;
              }
              &.v-cash-out-history-item-right-top-status-success {
                color: #07BF51;
              }
              &.v-cash-out-history-item-right-top-status-fail {
                color: #FF0000;
              }
              &.v-cash-out-history-item-right-top-status-deal {
                color: #ad1f61;
              }
            }
          }

          .v-cash-out-history-item-right-bottom {
            padding-top: 12px;
            font-size: 12px;
            color: #999999;
            .v-cash-out-history-item-right-bottom-type {
              flex: 1;
            }
          }
  
        }
      }
    }
  }
}
</style>