<template>
  <div class="s_cash_out_history_pop">
    <van-popup v-model="show" :safe-area-inset-bottom="true" position="right" :style="{width: '100%', height: '100%' }" class="s-cash-out-history-pop-container">
      <head-back :isRouterBack="false" @emitHeadBack="emitHeadBack">
        <template v-slot:returnText>
          <span>返回</span>
        </template>

         <template v-slot:default>
          <span>提现详情</span>
        </template>
      </head-back>

      <div class="s-cash-out-history-pop-content">
        <div class="s-cash-out-history-pop-top g-flex-column g-flex-align-center">
          <i class="iconfont icon-qian" v-show="form.bank_type === 1"></i>
          <i class="iconfont icon-qianbaozhifumaijia"  v-show="form.bank_type === 2"></i>
          <span class="s-cash-out-history-pop-top-type">{{form.title}}</span>
          <p class="s-cash-out-history-pop-top-card">提现到{{form.bank_name}}({{form.endnum}})</p>
          <h3 class="s-cash-out-history-pop-top-money">-{{form.pay_money}}</h3>
        </div>

        <div class="s-cash-out-history-pop-middle g-flex-align-start">
          <div class="s-cash-out-history-pop-middle-left">
            <span>当前状态</span>
              <!-- <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-fail" v-if="item.status == 0">失败</span>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-ing" v-if="item.status == 1">审核中</span>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-success" v-if="item.status == 2">已完成</span>
              <span class="v-cash-out-history-item-right-top-status v-cash-out-history-item-right-top-status-deal" v-if="item.status == 2">银行处理中</span> -->
          </div>
          <div class="s-cash-out-history-pop-middle-right"  v-show="form.status === 0">
            <h3 class="s-cash-out-history-pop-middle-right-list-fail">审核失败， 原因：{{form.remark}}</h3>
          </div>
          <div class="s-cash-out-history-pop-middle-right" v-show="form.status != 0">
            <ul class="s-cash-out-history-pop-middle-right-list">
              <li class="s-cash-out-history-pop-middle-right-list-item g-flex-column">
                <p>提现审核中</p>
                <span v-show="form.status == 1">{{formatDate(form.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                <div class="s-list-item-dot"></div>
                <div class="s-list-item-dot-success" v-show="form.status == 1">
                  <i class="iconfont icon-xianshi_xuanzetianchong"></i> 
                </div>
              </li>
              <li class="s-cash-out-history-pop-middle-right-list-item g-flex-column">
                <p>银行处理中</p>
                <span v-show="form.status == 3">{{formatDate(form.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                <div class="s-list-item-dot"></div>
                <div class="s-list-item-dot-success" v-show="form.status == 3">
                  <i class="iconfont icon-xianshi_xuanzetianchong"></i> 
                </div>
              </li>
              <li class="s-cash-out-history-pop-middle-right-list-item g-flex-column">
                <p>到账成功</p>
                <span v-show="form.status == 3">预计 {{formatDate(form.modify_time + 60*60*24*2, 'yyyy-MM-dd hh:mm:ss')}}</span>
                <span v-show="form.status == 2">{{formatDate(form.modify_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                <div class="s-list-item-dot"></div>
                <div class="s-list-item-dot-success" v-show="form.status == 2">
                  <i class="iconfont icon-xianshi_xuanzetianchong"></i> 
                </div>
              </li>
            </ul>
          </div>
        </div>

        <ul class="s-cash-out-history-pop-bottom">
          <li class="s-cash-out-history-pop-bottom-item g-flex-align-center">
            <span class="s-pop-bottom-item-title">提现金额</span>
            <p class="s-pop-bottom-item-val">¥{{form.pay_money}}</p>
          </li>
          <li class="s-cash-out-history-pop-bottom-item g-flex-align-center">
            <span class="s-pop-bottom-item-title">申请时间</span>
            <p class="s-pop-bottom-item-val">{{formatDate(form.create_time, 'yyyy-MM-dd hh:mm:ss')}}</p>
          </li>
          <li class="s-cash-out-history-pop-bottom-item g-flex-align-center">
            <span class="s-pop-bottom-item-title">到账时间</span>
            <p class="s-pop-bottom-item-val">预计两个工作日内到账</p>
          </li>
          <li class="s-cash-out-history-pop-bottom-item g-flex-align-center">
            <span class="s-pop-bottom-item-title">提现到</span>
            <p class="s-pop-bottom-item-val">{{form.bank_name}}</p>
          </li>
          <li class="s-cash-out-history-pop-bottom-item g-flex-align-center">
            <span class="s-pop-bottom-item-title">提现单号</span>
            <p class="s-pop-bottom-item-val">{{form.id}}</p>
          </li>
        </ul>

      </div>
    </van-popup>

  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { formatDate } from '../../utils/index.js'
  export default {
    components: { HeadBack },
    data() {
      return {
        show: false,
        form: {
          bank_name: "支付宝",
          bank_type: 2,
          card_number: "17759701303",
          create_time: 1608365542,
          endnum: "1303",
          fee: "0.00",
          id: 59,
          modify_time: 1608365542,
          name: "李金蔚",
          operator_id: 0,
          pay_money: "50.00",
          real_money: "50.00",
          remark: "",
          status: 1,
          title: "本金提现",
          type: 1,
          user_id: 185642,
        }
      }
    },
    methods: {
      formatDate,
      onShow(flag, obj = {}) {
        console.log(obj)
        if(obj !== {}) {
           obj.endnum = obj.card_number.substring(obj.card_number.length - 4, obj.card_number.legth)
           this.form = obj
        }
        this.show = flag
      },
      emitHeadBack() {
        this.show = false
      }
    }
  }
</script>

<style lang="scss">
.s_cash_out_history_pop {
  .van-overlay {
    background-color: #ffffff;;
  }
  .s-cash-out-history-pop-container {
    background: #F1F1F1;
    .s-cash-out-history-pop-content {
      padding: 0 12px;
      .s-cash-out-history-pop-top {
        padding-top: 25px;
        color: #000000;
        .iconfont {
          font-size: 50px;
          &.icon-qian {
            color: #FB560A;
          }
          &.icon-qianbaozhifumaijia {
            color: #3889FF;
          }
        }
        .s-cash-out-history-pop-top-type {
          font-size: 15px;
          padding-top: 8px;
        }
        .s-cash-out-history-pop-top-card {
          font-size: 15px;
          padding-top: 6px;
        }
        .s-cash-out-history-pop-top-money {
          font-size: 26px;
          padding-top: 12px;
        }
      }

      .s-cash-out-history-pop-middle {
        margin-top: 20px;
        background: $white;
        border-radius: 8px;
        padding: 15px 0 15px 18px;
        .s-cash-out-history-pop-middle-left {
          flex: 1;
          span {
            color: #797979;
            font-size: 13px;
          }
        }
        .s-cash-out-history-pop-middle-right {
          flex: 3;
          .s-cash-out-history-pop-middle-right-list-fail {
            color: $main_color;
            font-size: 14px;
            line-height: 18px;
          }
          .s-cash-out-history-pop-middle-right-list {
            padding-left: 36px;
            border-left: 1px dashed $main_color;
            .s-cash-out-history-pop-middle-right-list-item {
              position: relative;
              &:nth-of-type(1) {
                padding-top: 0;
              }
              padding-top: 20px;
              color: #797979;
              font-size: 14px;
              span {
                padding-top: 10px;
              }
              .s-list-item-dot {
                position: absolute;
                left: -36px;
                transform: translateX(-50%) translateY(50%);
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background: $main_color;
              }
              .s-list-item-dot-success {
                position: absolute;
                z-index: 999;
                background: white;
                transform: translateX(-50%);
                left: -36px;
                line-height: 1;
                .icon-xianshi_xuanzetianchong {
                  font-size: 34px;
                  color: $main_color;
                }
              }
            }
          }
        }
      }

      .s-cash-out-history-pop-bottom {
        margin-top: 10px;
        background: $white;
        border-radius: 8px;
        padding: 15px 10px 15px 18px;
        font-size: 13px;
        .s-cash-out-history-pop-bottom-item {
          padding-top: 18px;
          &:nth-of-type(1) {
            padding-top: 0;
          }
          .s-pop-bottom-item-title {
            flex: 1;
            color: #797979;
          }
          .s-pop-bottom-item-val {
            flex: 3;
            color: #151515;
            @include textManyOverflow();
            letter-spacing: 1px;
          }
        }

      }

    }

  }
}
</style>